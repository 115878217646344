<!-- settingAccount -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'account',
          name: 'Personal info',
        },
        {
          name: 'Contact info',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="My Account">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <Section title="Contact info">
        <div class="not-readonly-content">
          <div class="label">Phone</div>
          <div class="value">
            <PhoneNumber v-model="phoneNum" ref="phone"></PhoneNumber>
          </div>
        </div>
      </Section>
      <div class="text-right mar-r-20">
        <BaseButton @click="handleCancel">Cancel</BaseButton>
        <BaseButton type="primary" :disabled="isDisabled" @click="handleSubmit"
          >Save</BaseButton
        >
      </div>
    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    userInfo() {
      return this.user.userInfo;
    },
  },
  watch: {
    phoneNum: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          let phonePass =
            this.$refs.phone &&
            !this.$refs.phone.isError.show &&
            !!this.$refs.phone.value.phoneNumber;
          this.isDisabled = !phonePass;
        });
      },
    },
  },
  data() {
    return {
      menuDatas: [
        {
          name: "Personal info",
          key: "account",
          active: true,
        },
        {
          name: "Security",
          key: "security",
        },
      ],
      activeIndex: "account",
      isDisabled: true,
      snackbarData: {
        visible: false,
        content: "",
      },
      phoneNum: {
        countryCode: "+65",
        phoneNumber: "",
      },
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.$router.push({ name: item.key });
    },
    handleCancel() {
      this.$router.push("/account");
    },
    handleSubmit() {
      Ajax.post("/usermanage/user/updateUserInfoById", {
        userId: this.user.userId,
        countryCode: this.phoneNum.countryCode,
        phoneNumber: this.phoneNum.phoneNumber,
      }).then((res) => {
        this.userInfo.countryCode = this.phoneNum.countryCode;
        this.userInfo.phoneNumber = this.phoneNum.phoneNumber;
        this.$store.commit("SET_USER_INFO", this.userInfo);
        this.handleSnack("Contact info has been edited");
        this.$router.push("/account")
      });
    },
  },
  created() {
    this.phoneNum = {
      countryCode: this.userInfo.countryCode,
      phoneNumber: this.userInfo.phoneNumber,
    };
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.not-readonly-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 85%;
  overflow: auto;
  .label {
    width: 140px;
    flex-basis: 140px;
    display: flex;
  }
  .value {
    margin-top: 0;
    width: calc(100% - 140px);
  }
}
</style>