<!-- settingAccount -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'account',
          name: 'Personal info',
        },
        {
          name: 'Contact info',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="My Account">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <Section title="Password">
        <div class="section-info-main">
          <label class="label">New password</label>
          <gg-input
            ref="newPassword"
            v-model="formData.newPassword"
            type="password"
            inline
            :error="formData.newPwdErrorObj"
          />
        </div>
        <div class="section-info-main">
          <label class="label">Confirm new password</label>
          <gg-input
            ref="confirmNewPassword"
            v-model="formData.confirmNewPassword"
            type="password"
            inline
            :error="formData.confirmPwdErrorObj"
          />
        </div>
        <div class="bottom">
          <BaseButton @click="handleCancel">Cancel</BaseButton>
          <BaseButton
            type="primary"
            :disabled="!isDisabled"
            @click="handleSubmit"
            >Save</BaseButton
          >
        </div>
      </Section>
    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  name: "settingAccount",
  components: {},
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
    isDisabled() {
      if (
        this.formData.newPwdErrorObj.show ||
        this.formData.confirmPwdErrorObj.show
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "formData.newPassword"(res) {
      if (res.length < 8) {
        this.formData.newPwdErrorObj = {
          show: true,
          message: "Must have at least 8 characters",
        };
      } else {
        this.formData.newPwdErrorObj = {
          show: false,
          message: "Must have at least 8 characters",
        };
      }
    },
    "formData.confirmNewPassword"(res) {
      if (res.length < 8) {
        this.formData.confirmPwdErrorObj = {
          show: true,
          message: "Must have at least 8 characters",
        };
      } else {
        this.formData.confirmPwdErrorObj = {
          show: false,
          message: "Must have at least 8 characters",
        };
      }
    },
  },
  data() {
    return {
      menuDatas: [
        {
          name: "Personal info",
          key: "account",
          active: true,
        },
        {
          name: "Security",
          key: "security",
        },
      ],
      activeIndex: "security",
      formData: {
        newPassword: "",
        confirmNewPassword: "",
        newPwdErrorObj: {
          show: true,
          message: "",
        },
        confirmPwdErrorObj: {
          show: true,
          message: "",
        },
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.$router.push({ name: item.key });
    },
    handleCancel() {
      this.$router.push("/security");
    },
    handleSubmit() {
      if (this.formData.newPassword !== this.formData.confirmNewPassword) {
        this.formData.confirmPwdErrorObj = {
          show: true,
          message: "Passwords do not match",
        };

        return false;
      }
      Ajax.post("/usermanage/password/updatePasswd", {
        userId: this.userId,
        newPasswd: this.formData.confirmNewPassword,
      }).then((res) => {
        if (res.code === "0000") {
          this.handleSnack('Password has changed.');
          setTimeout(()=>{
            this.$router.push("/security");
            this.getUserInfo(this.userId);
          },1500);
        } else {
          this.formData.newPwdErrorObj = {
            show: true,
            message: res.message,
          };
        }
      });
    },
    getUserInfo(userId) {
      Ajax.get("/usermanage/user/selectUserInfoById", {
        id: userId,
      }).then((res) => {
        let userInfo = { ...res.data };
        this.$store.commit("SET_USER_INFO", userInfo);
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/.section-main {
  margin: 0;
}
/deep/.section-info-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70%;
  .label {
    display: flex;
    align-items: center;
    width: 260px !important;
    flex-basis: 260px;
  }
  .md-field {
    margin-top: 0;
    width: calc(100% - 260px);
  }
}
.bottom {
  padding: 20px;
  text-align: right;
}
/deep/.md-icon.md-theme-default.md-icon-image svg{
	fill:var(--md-theme-default-icon-on-background, rgba(0,0,0,0.54))!important
}
</style>
