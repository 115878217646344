var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
      {
        routeName: 'account',
        name: 'Personal info',
      },
      {
        name: 'Contact info',
      } ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"2","title":"My Account"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('gg-flex-menus',{attrs:{"menus":_vm.menuDatas,"default-active":_vm.activeIndex},on:{"select":_vm.menuSelect}})]},proxy:true}])},[_c('Section',{attrs:{"title":"Contact info"}},[_c('div',{staticClass:"not-readonly-content"},[_c('div',{staticClass:"label"},[_vm._v("Phone")]),_c('div',{staticClass:"value"},[_c('PhoneNumber',{ref:"phone",model:{value:(_vm.phoneNum),callback:function ($$v) {_vm.phoneNum=$$v},expression:"phoneNum"}})],1)])]),_c('div',{staticClass:"text-right mar-r-20"},[_c('BaseButton',{on:{"click":_vm.handleCancel}},[_vm._v("Cancel")]),_c('BaseButton',{attrs:{"type":"primary","disabled":_vm.isDisabled},on:{"click":_vm.handleSubmit}},[_vm._v("Save")])],1)],1),_c('Snackbar',{attrs:{"visible":_vm.snackbarData.visible,"content":_vm.snackbarData.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbarData, "visible", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }