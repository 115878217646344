<!-- Security -->
<template>
  <div class="section-nopad">
    <GgPage pageType="2" title="My Account">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <Section title="Security">
        <ul class="cardcontent-info">
          <li>
            <div class="info-wrapper-l">Password</div>
            <div class="info-wrapper-r">
              <div>********</div>
              <div class="security-info" v-if="user.userInfo.passwordModifyTime">
                Last changed {{responsesUserChangeTime(user.userInfo.passwordModifyTime)}}
                </div>
              <div class="security-info" v-else>
                Last changed -
              </div>
            </div>
            <i class="iconfont iconedit" @click="openDialogAdd"></i>
          </li>
        </ul>
      </Section>
    </GgPage>

    <!-- 身份认证卡片 -->
    <gg-dialog
      class="form-dialog"
      title="Verify user"
      :visible.sync="formDialogView.visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content">
        <div class="mar-b-20">To continue, first verify that it's you</div>
        <gg-input
          ref="newPassword"
          v-model="formDialogView.password"
          type="password"
          placeholder="Password"
          :error="formDialogView.errorObj"
        />
        <router-link
          @click.native="handleSentLink"
          tag="div"
          to=""
          class="forgot-txt"
          >Forgot password ?</router-link
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <slot name="footer">
          <baseButton @click="handleCancel">Cancel</baseButton>
          <baseButton
            type="primary"
            :disabled="formDialogView.isDisabled"
            @click="handleSubmit"
            >Next</baseButton
          >
        </slot>
      </span>
    </gg-dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {},
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      menuDatas: [
        {
          name: "Personal info",
          key: "account",
          active: true,
        },
        {
          name: "Security",
          key: "security",
        },
      ],
      activeIndex: "security",
      formDialogView: {
        visible: false,
        isDisabled: true,
        password: "",
        errorObj: {
          show: true,
          message: "Incorrect password",
        },
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  watch: {
    "formDialogView.password"(res) {
      if (res.length < 8) {
        this.formDialogView.errorObj = {
          show: true,
          message: "Must have at least 8 characters",
        };
        this.formDialogView.isDisabled = true;
      } else {
        this.formDialogView.errorObj = {
          show: false,
          message: "",
        };
        this.formDialogView.isDisabled = false;
      }
    },
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.$router.push({ name: item.key });
    },
    openDialogAdd() {
      this.formDialogView.visible = !this.formDialogView.visible;
    },
    handleCancel() {
      this.formDialogView.visible = false;
      this.formDialogView.password = "";
    },
    handleSubmit() {
      Ajax.get("/usermanage/user/checkAccountAndPassword", {
        account: this.user.userInfo.email,
        password: this.formDialogView.password,
      }).then((res) => {
        if (res.code === "0000") {
          this.$router.push("/accountPwd");
        } else {
          this.formDialogView.errorObj = {
            show: true,
            // message: "Incorrect password",
            message: res.message,
          };
        }
      });
    },
    handleSentLink() {
      this.formDialogView.visible = false;
      this.$confirm(
        `A reset password link will be sent to ${this.user.userInfo.email}`,
        "Reset password",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Send",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/email/resetPasswdByEmail", {
            email: this.user.userInfo.email,
          }).then((res) => {
            if (res.code === "0000") {
              this.handleSnack("Reset password link has been sent");
              // this.$router.push("/resetPassword");
            }
          });
        })
        .catch(() => {});
    },
    responsesUserChangeTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.security-info {
  margin-top: 5px;
  color: #b4b4b4;
  font-size: 14px;
  font-family: Roboto;
}
.forgot-txt {
  display: inline-block;
  padding-top: 20px;
  color: #1a73e8;
  cursor: pointer;
}
</style>
