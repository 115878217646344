<!-- My Account -->
<template>
  <GgPage pageType="2" title="My Account">
    <template v-slot:left>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <div class="section-nopad">
      <Section title="Profile" :hasFooter="false" class="mar-b-20">
        <ul class="cardcontent-info">
          <li class="logoItem">
            <div class="lable">Photo</div>
            <div class="info-wrapper">
              <div class="info-wrapper-l">
                <head-img
                  :src="userInfoData.photo"
                  :firstName="userInfoData.firstName"
                  :lastName="userInfoData.lastName"
                  line-height="88"
                />
              </div>
            </div>
            <i class="iconfont iconedit" @click="isImgCutShow = true"></i>
          </li>
          <li>
            <div class="lable">Basic info</div>
            <div class="info-wrapper">
              <ul class="info-wrapper-default">
                <li>
                  <div class="info-wrapper-l">Name</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.firstName }} {{ userInfoData.lastName }}
                  </div>
                </li>
                <li>
                  <div class="info-wrapper-l">Gender</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.gender }}
                  </div>
                </li>
                <li>
                  <div class="info-wrapper-l">Nationality</div>
                  <div class="info-wrapper-r">
                    <div>
                      {{ userInfoData.nationality }}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="info-wrapper-l">Working type</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.workType }}
                  </div>
                </li>
                <li>
                  <div class="info-wrapper-l">Working title</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.workTitle }}
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="lable">Contact info</div>
            <div class="info-wrapper">
              <ul class="info-wrapper-default">
                <li>
                  <div class="info-wrapper-l">Email</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.email }}
                  </div>
                </li>
                <li>
                  <div class="info-wrapper-l">Phone</div>
                  <div class="info-wrapper-r">
                    {{ userInfoData.countryCode }}{{ userInfoData.phoneNumber }}
                  </div>
                </li>
              </ul>
            </div>
            <i
              class="iconfont iconedit"
              @click="
                () => {
                  this.$router.push({
                    name: 'settingAccount',
                  });
                }
              "
            ></i>
          </li>
        </ul>
      </Section>
    </div>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { lightFormat } from "date-fns";
export default {
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    userInfoData() {
      return this.user.userInfo;
    },
  },
  data() {
    return {
      menuDatas: [
        {
          name: "Personal info",
          key: "account",
          active: true,
        },
        {
          name: "Security",
          key: "security",
        },
      ],
      activeIndex: "account",
      isImgCutShow: false,
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.code === "0000") {
            this.updateUserInfo(
              `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
            );
            this.userInfoData.photo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
            this.$store.commit("SET_USER_INFO", this.userInfoData);
          }
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    updateUserInfo(val) {
      Ajax.post("/usermanage/user/updateUserInfoById", {
        userId: this.user.userId,
        photo: val,
      }).then((res) => {
        if (res.code === "0000") {
          this.isImgCutShow = false;
          this.handleSnack("Photo has been edited");
        }
      });
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.$router.push({ name: item.key });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>